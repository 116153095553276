import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import { Redirect } from "react-router-dom";
import logo from "../../img/logo_KI-Kurs.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSchoolLock } from "@fortawesome/free-solid-svg-icons";

class LoginClasscode extends Form {
  state = {
    data: {
      username: "",
      classcode: "",
    },
    errors: {},
    loginError: "",
    loading: false,
    isStudent: true,
  };

  schema = {
    username: Joi.string().required(),
    classcode: Joi.string().required(),
  };

  submitForm = () => {
    this.setState({
      loginError: "",
      loading: true,
    });
    const { username, classcode } = this.state.data;
    const isStudent = this.state.isStudent;
    auth
      .loginClasscode(username, classcode)
      .then(() => {
        const { state } = this.props.location;
        window.location = "/home";
        this.props.history.push({
          pathname: "/ethics",
          data: {
            isStudent: isStudent,
          },
        });
      })
      .catch((err) => {
        let error = "Es ist ein unerwarteter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
        } else if (err.response && err.response.status === 403) {
          error = err.response.data.error;
        }

        this.setState({
          loginError: error,
          loading: false,
        });
      });
  };

  render() {
    if (auth.getCurrentUser())
      return (
        <Redirect
          to={{
            pathname: window.location,
            state: { isStudent: true },
          }}
        />
      );
    const { data, errors, loginError, loading } = this.state;
    const { username, classcode } = data;
    const { activeTab, changeTabs } = this.props;

    return (
      <div
        className={`content-login-wrapper user-content ${
          activeTab === "classcode" ? "active" : ""
        }`}
      >
        <div className="login-logo">
          <img alt="new KI-Kurs logo" src={logo} />
        </div>
        <a
          className="verlinkung"
          href="https://competition.bw-ki.de/entry-page"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hier geht's stattdessen zum KI-Wettbewerb
        </a>
        <form>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={faUser} />
            <input
              className={`${errors.username ? "invalid" : ""}`}
              name="username"
              onChange={this.handleChange}
              value={username}
              type="text"
              placeholder="Benutzername"
            />
          </div>
          <div className="input-wrapper">
            <FontAwesomeIcon className="icon" icon={faSchoolLock} />
            <input
              className={`${errors.password ? "invalid" : ""}`}
              name="classcode"
              onChange={this.handleChange}
              value={classcode}
              type="password"
              placeholder="Klassencode"
            />
          </div>
          {/* <a className='float-right' onClick={() => changeTabs('forgot')} ><b>Passwort vergessen?</b></a> */}
          {/* <a className='float-left' onClick={() => changeTabs('classencode')} ><b>Anmeldung mit Klassencode</b></a> */}
          <div className="button-login">
            <button
              disabled={loading ? true : this.validate()}
              type="submit"
              onClick={this.handleSubmit}
            >
              Anmelden
            </button>
          </div>
        </form>
        {loginError && (
          <div className="error text-center">
            <span className="text-danger">{loginError}</span>
          </div>
        )}
      </div>
    );
  }
}

export default LoginClasscode;
