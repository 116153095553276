import React, { Component } from "react";
import entryPageLogo from "../img/logo_KI-Kurs.png";
import { Link } from "react-router-dom";
import auth from "../services/authService";
import FindUs from "../components/entry-page/footer/find-us";
import Sponsors from "../components/entry-page/footer/sponsors";
import Footer from "../components/entry-page/footer/footer";
// import brochurePDF from '../pdfs/Syllabus_Druckversion2024.pdf'
import "./entry-page/main.css";
import Info from "./entry-page/info";
import Intro from "./entry-page/intro";
import Faqs from "./entry-page/faq";

class EntryPage extends Component {
  render() {
    if (auth.getCurrentUser()) this.props.history.push("/home");
    return (
      <React.Fragment>
        <div className={`womix__backgroundcliptext`}>
          <section className="content">
            <div className="container-fluid home-wrapper margin-top-5">
              <div className="middle-content entry">
                <div className={`entry-left`}>
                  <img src={entryPageLogo} alt="new KI-Kurs logo 2024" />
                  <div className="auth-buttons-container">
                    <Link className="button primary" to={"/auth"}>
                      Anmelden / Registrieren
                    </Link>
                    <Link className="button secondary" to={"/home"}>
                      Ohne Registrierung anschauen
                    </Link>
                  </div>
                </div>
                <div className={`entry-right`}>
                  <span className={`title`}>
                    Lerne die <br />
                    Grundlagen der KI
                  </span>
                  <p className="entry-text">
                    Lerne die Ursprünge der KI, entwickle dein erstes KI-Projekt
                    und verstehe, wie KI in unserer Gesellschaft ethisch
                    wertvoll eingesetzt werden kann.
                  </p>
                  <div className="info-links-container">
                    <a className="info-link" href="#infos-lehrende">
                      <span className="infos-highlighter">
                        {" "}
                        &gt; Infos für Lehrende und Lernende
                      </span>
                    </a>
                    <a
                      className="info-link"
                      href="/pdfs/Syllabus_2025.pdf"
                      target="_blank"
                      referrerPolicy="no-referrer"
                    >
                      <span className="infos-highlighter">
                        {" "}
                        &gt; Download der Kursbroschüre
                      </span>
                    </a>
                  </div>

                  <div className="logos-wrapper">
                    <a
                      href="https://www.instagram.com/bundeswettbewerb.ki/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        className="social-logo"
                        src={`/images/icons/media/instagram.svg`}
                      />
                    </a>
                    <a
                      href="https://www.tiktok.com/@ki_kurzerklaert"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        className="social-logo"
                        src={`/images/icons/media/tiktok.svg`}
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCxk0eSeegXCF0fOgMa8DjHA"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        className="social-logo"
                        src={`/images/icons/media/youtube.svg`}
                      />
                    </a>
                    <a
                      href="https://discord.gg/V2Tvgr5NQz"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        className="social-logo"
                        src={`/images/icons/media/discord.svg`}
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/bwki"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        className="social-logo"
                        src={`/images/icons/media/linkedin.svg`}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="footer">
            <div className="womix__header-graphic">
              <svg
                version="1.1"
                className="header_layer header_graphic-layer-1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1497.5 125"
              >
                <polygon
                  className="st0"
                  points="0,126.21 229.5,30 389.5,78 769.5,15 955.5,68 1259.5,0 1562,132.5 0,132.5 "
                ></polygon>
              </svg>
              <svg
                version="1.1"
                className="header_layer header_graphic-layer-2"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1497.5 125"
              >
                <polygon
                  className="st0"
                  points="0,126.21 229.5,30 389.5,78 769.5,15 955.5,68 1259.5,0 1562,132.5 0,132.5 "
                ></polygon>
              </svg>
              <svg
                version="1.1"
                className="header_layer header_graphic-layer-3"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1497.5 125"
              >
                <polygon
                  className="st0"
                  points="0,126.21 229.5,30 389.5,78 769.5,15 955.5,68 1259.5,0 1562,132.5 0,132.5 "
                ></polygon>
              </svg>
            </div>
          </section>
          <div className={`entry-page`}>
            <main>
              <Intro />
              <Info />
              <Faqs />
              <footer
                className="scrollbar-padding"
                style={{ paddingRight: 17 }}
              >
                <FindUs />
                <Sponsors />
                <Footer />
              </footer>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EntryPage;
